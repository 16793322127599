import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoDark from "../assets/images/logo.png";
import logoLight from "../assets/images/logo.png";
import { API } from "../config/urls";
import axios from "axios";

export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchNavData = async () => {
      try {
        const response = await axios.get(API.getheader);
        setData(response.data?.[0] || {});
      } catch (error) {
        console.error("Error fetching navigation data:", error);
      }
    };

    fetchNavData();
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isActiveClassName = (path) => (path === pathname ? "active" : "");

  const renderMenuLinks = () => {
    const { contents = [] } = data;

    return (
      <>
        {contents.map((item) => {
          const mainPath =
            item.content?.[0]?.title.toLowerCase().replace(/\s/g, "-") || "";
          return (
            <li className="nav-item ms-0 relative group" key={item.id}>
              <Link
                to={`/${mainPath}`}
                className={`hover:text-red-600 ${isActiveClassName(
                  `/${mainPath}`
                )}`}
              >
                {item.name}
              </Link>
              {item.content && item.content.length > 0 && (
                <ul className="absolute left-0 mt-2 w-48 bg-white text-black shadow-lg rounded-sm opacity-0 group-hover:opacity-100 transform group-hover:translate-y-0 transition-all duration-300 ease-in-out z-10">
                  {item.content.map((subItem) => {
                    const subPath =
                      subItem.title.toLowerCase().replace(/\s/g, "-") || "";
                    return (
                      <li className="nav-item ms-0" key={subItem.id}>
                        <Link
                          to={`/${subPath}`}
                          className="block px-4 py-2 hover:bg-gray-100 hover:text-red-500 rounded-sm"
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </>
    );
  };

  return (
    <nav
      className={`navbar sticky top-0 z-50 py-4 transition-all duration-300 ${
        scroll ? "bg-white shadow-md dark:bg-dark" : "bg-transparent"
      }`}
      id="navbar"
    >
      <div className="container relative flex flex-wrap items-center justify-between">
        <Link className="navbar-brand md:me-8" to="/">
          <img
            // src={URL + data?.logo || logoDark}
            src={logoDark}
            className="inline-block dark:hidden w-32"
            alt="Logo"
          />
          <img
            src={URL + data?.logo || logoLight}
            className="hidden dark:inline-block w-32"
            alt="Logo"
          />
        </Link>

        <div className="nav-icons flex items-center lg_992:order-2 ms-auto md:ms-8">
          <ul className="list-none menu-social mb-0">
            <li className="inline">
              {data?.custom_url?.map((item, index) => (
                <Link
                  to={`/${item.custom_url[0]?.link}`}
                  key={index}
                  className="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-md bg-[#bd2131] text-white uppercase"
                >
                  {item.custom_url[0]?.name}
                </Link>
              ))}
            </li>
          </ul>
          <button
            data-collapse="menu-collapse"
            type="button"
            className="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden"
            onClick={() => setMenu(!menu)}
          >
            <span className="sr-only">Navigation Menu</span>
            <i className="mdi mdi-menu text-[24px]"></i>
          </button>
        </div>

        <nav
          className={`navigation lg_992:flex ms-auto ${menu ? "" : "hidden"}`}
          id="menu-collapse"
        >
          <ul className="flex space-x-8" id="navbar-navlist">
            {renderMenuLinks()}
          </ul>
        </nav>
      </div>
    </nav>
  );
}
