import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { API } from "../config/urls";
import toast from "react-hot-toast";

const schema = z
  .object({
    first_name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    email: z.string().email("Invalid email address"),
    phone_number: z.string().min(1, "Phone number is required"),
    organization_name: z.string().min(1, "Organization name is required"),
    address1: z.string().min(1, "Address is required"),
    address2: z.string().optional(),
    zip_code: z.string().min(1, "Zip code is required"),
    state: z.string().min(1, "State is required"),
    city: z.string().min(1, "City is required"),
    password: z.string().min(6, "Password must be at least 6 characters long"),
    passwordConfirm: z.string().min(6, "Password confirmation is required"),
    acceptTermsConditions: z
      .boolean()
      .refine(
        (val) => val === true,
        "You must accept the terms and conditions"
      ),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ["passwordConfirm"],
  });

const SignUp = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    const payload = {
      organization_name: data.organization_name,
      address1: data.address1,
      address2: data.address2,
      zip_code: data.zip_code,
      state: data.state,
      city: data.city,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
      acceptTermsConditions: data.acceptTermsConditions,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      signup_form: true,
    };
    try {
      const response = await fetch(API.postSignup, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Form Submitted Successfully");
        reset();
        window.location.href = "https://dashboard.electionforfree.com/login";
      } else {
        const errorData = await response.json();
        console.log(errorData);
        toast.error(errorData.message);
      }
    } catch (error) {
      console.log(error, payload);
      toast.error("Form not Submitted");
    }
  };
  return (
    <section
      className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800"
      id="signup"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <p className="text-slate-400 max-w-xl mx-auto">Sign Up</p>
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            Please fill in the details to create your account.
          </h3>
        </div>

        <div className="flex  w-full justify-center">
          <div className="lg:ms-5">
            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Personal Details */}
                <div className="grid lg:grid-cols-2 gap-4 mb-6">
                  <div>
                    <label htmlFor="first_name" className="font-semibold">
                      First Name:
                    </label>
                    <input
                      id="first_name"
                      {...register("first_name")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="First Name"
                    />
                    {errors.first_name && (
                      <p className="text-red-500">
                        {errors.first_name.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="last_name" className="font-semibold">
                      Last Name:
                    </label>
                    <input
                      id="last_name"
                      {...register("last_name")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Last Name"
                    />
                    {errors.last_name && (
                      <p className="text-red-500">{errors.last_name.message}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="email" className="font-semibold">
                      Email:
                    </label>
                    <input
                      id="email"
                      type="email"
                      {...register("email")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Email"
                    />
                    {errors.email && (
                      <p className="text-red-500">{errors.email.message}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="phone_number" className="font-semibold">
                      Phone Number:
                    </label>
                    <input
                      id="phone_number"
                      type="tel"
                      {...register("phone_number")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Phone Number"
                    />
                    {errors.phone_number && (
                      <p className="text-red-500">
                        {errors.phone_number.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Organization Details */}
                <div className="grid lg:grid-cols-2 gap-4 mb-6">
                  <div>
                    <label
                      htmlFor="organization_name"
                      className="font-semibold"
                    >
                      Organization Name:
                    </label>
                    <input
                      id="organization_name"
                      {...register("organization_name")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Organization Name"
                    />
                    {errors.organization_name && (
                      <p className="text-red-500">
                        {errors.organization_name.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="address1" className="font-semibold">
                      Address 1:
                    </label>
                    <input
                      id="address1"
                      {...register("address1")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Address 1"
                    />
                    {errors.address1 && (
                      <p className="text-red-500">{errors.address1.message}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="address2" className="font-semibold">
                      Address 2 (Optional):
                    </label>
                    <input
                      id="address2"
                      {...register("address2")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Address 2"
                    />
                  </div>
                  <div>
                    <label htmlFor="zip_code" className="font-semibold">
                      Zip Code:
                    </label>
                    <input
                      id="zip_code"
                      {...register("zip_code")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Zip Code"
                    />
                    {errors.zip_code && (
                      <p className="text-red-500">{errors.zip_code.message}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="state" className="font-semibold">
                      State:
                    </label>
                    <input
                      id="state"
                      {...register("state")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="State"
                    />
                    {errors.state && (
                      <p className="text-red-500">{errors.state.message}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="city" className="font-semibold">
                      City:
                    </label>
                    <input
                      id="city"
                      {...register("city")}
                      className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="City"
                    />
                    {errors.city && (
                      <p className="text-red-500">{errors.city.message}</p>
                    )}
                  </div>
                </div>

                {/* Password */}
                <div className="mb-6">
                  <label htmlFor="password" className="font-semibold">
                    Password:
                  </label>
                  <input
                    id="password"
                    type="password"
                    {...register("password")}
                    className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    placeholder="Password"
                  />
                  {errors.password && (
                    <p className="text-red-500">{errors.password.message}</p>
                  )}
                </div>
                <div className="mb-6">
                  <label htmlFor="passwordConfirm" className="font-semibold">
                    Confirm Password:
                  </label>
                  <input
                    id="passwordConfirm"
                    type="password"
                    {...register("passwordConfirm")}
                    className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    placeholder="Confirm Password"
                  />
                  {errors.passwordConfirm && (
                    <p className="text-red-500">
                      {errors.passwordConfirm.message}
                    </p>
                  )}
                </div>

                {/* Terms and Conditions */}
                <div className="mb-6">
                  <input
                    id="acceptTermsConditions"
                    type="checkbox"
                    {...register("acceptTermsConditions")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="acceptTermsConditions"
                    className="font-semibold"
                  >
                    I accept the terms and conditions
                  </label>
                  {errors.acceptTermsConditions && (
                    <p className="text-red-500">
                      {errors.acceptTermsConditions.message}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-fit py-1 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
