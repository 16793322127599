import React from "react";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaVoteYea, FaUserCheck } from "react-icons/fa";
import {
  BsFillPeopleFill,
  BsCheck2Circle,
  BsCalendarCheck,
} from "react-icons/bs";
import { GiVote } from "react-icons/gi";

const Blocks = ({ data }) => {
  const bottomIcons = [
    <FaVoteYea className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <GiVote className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsFillPeopleFill className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <FaUserCheck className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsCheck2Circle className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsCalendarCheck className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
  ];

  const renderBlock = (item, index) => {
    let block;
    if (index % 2 === 0) {
      block = designOne(item, index);
    } else {
      block = designTwo(item, index);
    }

    if (item.child_blocks && item.child_blocks.length > 0) {
      return (
        <div key={index}>
          {block}
          <div className="ml-4">
            {item.child_blocks.map((child, childIndex) =>
              renderBlock(child, `${index}-${childIndex}`)
            )}
          </div>
        </div>
      );
    }

    return block;
  };

  const designOne = (item, index) => (
    <section
      key={index}
      className={`flex flex-col gap-4 md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8 mb-12 ${
        item.image[0]?.position === "RIGHT"
          ? "md:flex-row "
          : "md:flex-row-reverse"
      }`}
    >
      {item?.image?.[0] && (
        <div className="md:w-1/2">
          <img
            src={item.image[0]?.image || "https://via.placeholder.com/800x500"}
            className="rounded-md shadow-md w-full h-auto object-cover"
            alt={item?.image[0]?.caption || "Feature image"}
          />
        </div>
      )}
      <div className="md:w-1/2 my-10">
        {item?.sub_heading && (
          <h6 className="text-blue-500 text-sm font-semibold uppercase mb-2">
            {item?.sub_heading}
          </h6>
        )}
        <h3 className="font-bold text-2xl mb-4">{item?.heading}</h3>
        <div
          className="mb-6 text-slate-500 dark:text-gray-300 text-justify"
          dangerouslySetInnerHTML={{ __html: item?.description }}
        ></div>
        {item?.custom_fields?.length > 0 && (
          <ul className="space-y-2 mb-4 grid grid-cols-2">
            {item.custom_fields.map((field, idx) => (
              <p key={idx} className="flex items-center">
                <FaCheckCircle className="text-blue-500 mr-2" />
                <span>{field.name}</span>
              </p>
            ))}
          </ul>
        )}
        {item?.custom_url?.length > 0 && (
          <div className="mt-4 space-y-2">
            {item.custom_url.map((link, idx) => (
              <Link
                key={idx}
                to={link.link}
                className="text-white rounded w-fit items-center  flex px-4 py-2 bg-red-600 hover:bg-red-500 hover:shadow-sm transition-all duration-300 ease-in-out"
              >
                {link.name} <FaArrowRight className="ml-2" />
              </Link>
            ))}
          </div>
        )}
      </div>
    </section>
  );

  const designTwo = (item, index) => (
    <section
      key={index}
      className="flex flex-col items-center text-center mb-12"
    >
      <div className=" relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h6 className="text-blue-500 text-sm font-semibold uppercase mb-2">
            {item?.heading}
          </h6>
          {item?.sub_heading && (
            <h3 className="font-bold text-2xl mb-4">{item?.sub_heading}</h3>
          )}
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
          {item?.custom_fields.map((field, idx) => (
            <div
              className="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit"
              key={idx}
            >
              <div className="content relative z-10">
                <Link
                  to=""
                  className="title text-lg font-semibold hover:text-blue-500"
                >
                  {field.name}
                </Link>
                {field?.custom_field_collections?.map(
                  (collection, collectionIdx) => (
                    <p
                      key={collectionIdx}
                      className="text-slate-500 mt-3 text-justify"
                    >
                      {collection?.char}
                    </p>
                  )
                )}
              </div>
              <div className="absolute bottom-0 -end-16">
                {bottomIcons[idx % bottomIcons.length]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );

  return (
    <div>{data?.block?.map((item, index) => renderBlock(item, index))}</div>
  );
};

export default Blocks;
