import React from "react";
import aboutImage from "../assets/images/about.jpg";

import { FaCheckCircle } from "react-icons/fa";

export default function About({ data }) {
  return (
    <section className="relative md:py-24 py-16" id="about">
      <div className="container relative">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="md:col-span-6">
            <div className="lg:me-8">
              <div className="relative">
                <img
                  src={`${data?.image}` || aboutImage}
                  className="rounded-full shadow dark:shadow-gray-700"
                  alt=""
                />

                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700">
                  <div className="text-center">
                    <span className="font-semibold block mt-2">
                      {data?.sub_heading}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-6">
            <div className="lg:ms-8">
              <h6 className="text-blue-500 text-sm font-semibold uppercase mb-2">
                {data?.sub_heading}
              </h6>
              <h3 className="font-semibold text-2xl leading-normal mb-2">
                {data?.heading}
              </h3>

              <div
                className="mb-6"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></div>
              <ul className="grid grid-cols-2 justify-start">
                {data?.custom_fields.map((service, index) => (
                  <p key={index} className="flex items-center">
                    <FaCheckCircle className="text-red-500 mr-2" />
                    <span>{service.name}</span>
                  </p>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
