import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import arrow icons
import { API } from "../config/urls";
import axios from "axios";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const [data, setData] = useState();
  const GetFaq = () => {
    var config = {
      method: "get",
      url: API.getfaqs,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setData(response.data.block);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetFaq();
  }, []);
  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <h6 className="text-blue-500 text-sm font-semibold uppercase mb-2">
          {data?.heading}
        </h6>
        <h3 className="font-semibold text-2xl leading-normal mb-6">
          {data?.sub_heading}
        </h3>
        <div className="space-y-4">
          {data?.custom_fields.map((faq, index) => (
            <div
              key={index}
              className="border rounded-md bg-white dark:bg-gray-800"
            >
              <button
                className="w-full px-4 py-3 text-left text-gray-800 dark:text-gray-200 font-medium hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                <h3 className="text-lg">{faq.name}</h3>
                <span className="text-gray-500 dark:text-gray-300">
                  {openIndex === index ? (
                    <FaChevronUp className="transition-transform duration-300" />
                  ) : (
                    <FaChevronDown className="transition-transform duration-300" />
                  )}
                </span>
              </button>
              <div
                className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-screen p-4" : "max-h-0 p-0"
                }`}
              >
                <p className="text-gray-600 dark:text-gray-400">
                  {faq.custom_field_collections[0].char}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
