import React from "react";
import bg from "../assets/images/bg/bg2.png";
import SignUp from "./Signup";
import Blocks from "./NewDetailPage";
import GetInTouch from "./getInTuoch";

const DynamicDetailPage = ({ data }) => {
  return (
    <>
      {/* Hero Section */}
      <section
        className="relative flex items-center justify-center w-full h-[30vh] bg-gray-900 bg-center bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className="text-4xl text-white font-bold uppercase ">
          {data?.title}
        </h1>
      </section>
      {!data?.show_contact_form && !data?.show_signup_form && (
        <section className="p-6 md:p-12 container">
          <Blocks data={data} />
        </section>
      )}
      {data?.show_contact_form && <GetInTouch />}
      {data?.show_signup_form && <SignUp />}
    </>
  );
};

export default DynamicDetailPage;
