import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoLight from "../assets/images/logo.png";
import axios from "axios";
import { API } from "../config/urls";
import toast from "react-hot-toast";

export default function Footer() {
  const [footerData, setFooterData] = useState([]);
  const [input, setInput] = useState({ email: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API.postNewsletter, input)
      .then((res) => {
        toast.success("Form submitted successfully");
        setInput({ email: "" });
      })
      .catch((err) => toast.error("Form not submitted"));
  };

  const fetchFooterData = () => {
    axios
      .get(API.getfooter)
      .then((response) => {
        setFooterData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching footer data:", error);
      });
  };
  useEffect(() => {
    fetchFooterData();
  }, []);

  return (
    <footer className="bg-dark-footer text-gray-200 dark:text-gray-200">
      <div className="py-[30px] px-0 bg-[#1E293B] border-b border-slate-800">
        <div className="container">
          <div className="flex items-center justify-between ">
            <div className="text-center">
              <Link to="#" className="text-[22px] focus:outline-none">
                <img src={logoLight} className="mx-auto w-32" alt="Logo" />
              </Link>
            </div>
            <div className="lg:col-span-6 md:col-span-4 text-center md:text-start">
              <div className="subcribe-form mt-6 mb-3">
                <form
                  className="relative max-w-xl mx-auto"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="email"
                    name="email"
                    value={input.email}
                    onChange={handleChange}
                    className="py-4 pe-40 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white/20 dark:bg-slate-900/60 shadow dark:shadow-gray-800"
                    placeholder="yourname@domain.com"
                  />
                  <button
                    type="submit"
                    className="px-6 tracking-wide inline-flex items-center justify-center font-medium absolute top-[2px] end-[3px] h-[46px] bg-red-700 text-white rounded-full"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[30px] px-0 border-b border-slate-800">
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-4 grid-cols-1 gap-6">
            {footerData[0]?.contents?.map((section, index) => (
              <div
                key={index}
                className="lg:col-span-3 md:col-span-2 text-center md:text-start"
              >
                <h4 className="font-semibold text-lg mb-3">{section?.name}</h4>
                <ul className="list-none">
                  {section?.content?.map((content, contentIndex) => (
                    <p key={contentIndex} className="mb-2">
                      <Link
                        to={`/${content?.title
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                        className="text-gray-400 hover:text-blue-500"
                      >
                        {content?.title}
                      </Link>
                    </p>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container">
          <p className="mb-0">
            © {new Date().getFullYear()} Election For Free. All Rights Reserved
            | by Ballot Express.
          </p>
        </div>
      </div>
    </footer>
  );
}
