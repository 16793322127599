import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import heroImg from "../assets/images/about2.jpg";
import bg from "../assets/images/bg/2.png";
import { FaVoteYea } from "react-icons/fa";

export default function HeroSection({ data }) {
  return (
    <section
      className="relative flex items-center md:h-screen py-36 bg-no-repeat bg-center bg-cover"
      id="home"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container relative">
        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-6 gap-6 relative">
          <div className="lg:col-span-7 md:me-6">
            <h4 className="font-semibold lg:leading-normal leading-normal tracking-wide text-4xl lg:text-5xl mb-5">
              {data?.heading}
            </h4>
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            {data?.custom_url && data?.custom_url?.[0] && (
              <div className="relative mt-6 space-x-1">
                <Link
                  to={data?.custom_url?.[0]?.link}
                  className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-500 text-white"
                >
                  {data?.custom_url?.[0]?.name}
                </Link>
              </div>
            )}
          </div>

          <div className="lg:col-span-5">
            <div className="relative">
              <img
                // src={`${URL + bg}` || heroImg}
                src={heroImg}
                className="mx-auto rounded-[150px] rounded-br-2xl shadow dark:shadow-gray-700 w-[90%]"
                alt=""
              />

              <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-blue-500/20 after:top-0 after:start-0 after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>

              <div className="absolute flex justify-between items-center bottom-16 md:-start-10 -start-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-60 m-3">
                <div className="flex items-center">
                  <div className="flex items-center justify-center h-[65px] min-w-[65px] bg-red-500/5 text-red-500 text-center rounded-full me-3">
                    <FaVoteYea className="h-6 w-6" />
                  </div>
                  <div className="flex-1">
                    <span className="text-slate-400">Total Election</span>
                    <p className="text-xl font-bold">
                      <CountUp className="counter-value" start={0} end={4589} />
                    </p>
                  </div>
                </div>
              </div>

              <div className="absolute top-16 md:-end-10 -end-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-48 m-3">
                <h5 className="text-lg font-semibold mb-3">Voter Turnout</h5>
                <div className="flex justify-between mt-3 mb-2">
                  <span className="text-slate-400">Progress</span>
                  <span className="text-slate-400">84%</span>
                </div>
                <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                  <div
                    className="bg-red-500 h-[6px] rounded-full"
                    style={{ width: "84%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
