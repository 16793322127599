// const URL = process.env.REACT_APP_APPURLMAIN;
// const URL = "https://admin.virtualcaremart.com";

const URL = "https://dev.electionforfree.com";
const localURL = "https://dev.electionforfree.com";

const URLs = URL + "/api/";
const frontUrl = localURL + "/api/v1/cms/";
const addon = "?populate=deep,10";

const API = {
  gethome: URLs + "index" + addon,
  gethowitworks: frontUrl + "content/how-it-works/",
  getheader: frontUrl + "header/",
  getdocs: frontUrl + "docs/",
  getfooter: frontUrl + "footer/",
  getpackages: frontUrl + "content/packages/",
  getabout: frontUrl + "content/about-telehealth/",
  getour_providers: frontUrl + "content/our-provider/",
  getwhy_us: frontUrl + "content/why-us/",
  getvirtual: URLs + "virtualprimarycare" + addon,
  getwhat_we_treat: frontUrl + "content/what-we-do/",
  getwho_we_serve: frontUrl + "content/who-we-serve/",
  getservices: frontUrl + "content/services/",
  getcontact: frontUrl + "content/contact-us/",
  getreviews: URLs + "reviews" + addon,
  getoffice_signup: URLs + "office-signup" + addon,
  checkemail: URLs + "external/email_checker",
  addformdata: frontUrl + "form-datas/",
  getPricing: URLs + "v1/cms/subscription/",
  getallprices: URLs + "external/getpackages/",
  getindvprice: URLs + "external/getindvpackages/",
  getusertype: frontUrl + "content/usertype/",

  // getBanner: frontUrl + "block/hero-section/",
  getServices: frontUrl + "block/services/",
  getfaqs: frontUrl + "block/faqs/",
  getButtons: frontUrl + "block/sign-up/",
  getWhoMakesUs: frontUrl + "block/who-makes-us",
  getservicesBlock: frontUrl + "block/service",

  getStandardPackage: localURL + "/api/v1/get_standard_package/",
  getDoctorList: localURL + "/api/v1/cms/doctors/",
  getBusinessType: localURL + "/api/v1/business-categories/",

  postContact: localURL + "/api/v1/contactus/",
  postSignup: localURL + "/api/v1/register/",
  postAppointment: localURL + "/api/v1/create-appointment/",
  postNewsletter : localURL + "/api/v1/subscribe-newsletter/",
};

export { API, URL, URLs, localURL };
