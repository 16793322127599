import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../components/navbar";
import About from "../components/about";
import Services from "../components/services";
import Client from "../components/client";
import Pricing from "../components/pricing";
import GetInTouch from "../components/getInTuoch";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import "../../node_modules/react-modal-video/scss/modal-video.scss";
import HeroSection from "../components/heroSection";
import { localURL } from "../config/urls";
import axios from "axios";
import { imgconvert } from "../helpers/imgconverter";
import SEO from "../helpers/seo";
import FAQ from "../components/faqs";

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};
export default function IndexThree() {
  const { pathname } = useLocation();
  const [data, setData] = useState(null);

  const config = useMemo(
    () => ({
      method: "get",
      url: `${localURL}/api/v1/cms/content/homepage/`,
      headers: {},
    }),
    []
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await axios(config);
      setData(response.data);
    } catch (err) {
    } finally {
    }
  }, [config]);

  useEffect(() => {
    const debouncedFetchData = debounce(fetchData, 500);
    debouncedFetchData();
  }, [pathname, fetchData]);

  const { seo, block } = data || {};
  console.log(block);

  const seoTitle = seo?.title;
  const seoDescription = seo?.description;
  const seoImage = seo?.image;
  const seoType = seo?.type;

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={imgconvert(seoImage)}
        name={seoTitle}
        type={seoType}
      />
      <Navbar />
      <HeroSection data={block?.[0]} />
      <About data={block?.[2]} />
      <Services data={block?.[1]} />
      <Client data={block?.[4]} />
      <Pricing />
      <FAQ />
      <GetInTouch />
      <Footer />
      <Switcher />
    </>
  );
}
