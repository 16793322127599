import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../config/urls";
import { FaCheckCircle } from "react-icons/fa"; // Importing a check icon

export default function Pricing() {
  const [pricingData, setPricingData] = useState([]);

  useEffect(() => {
    axios
      .get(API.getPricing)
      .then((response) => {
        setPricingData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section
      className="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
      id="pricing"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            Our Pricing
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            We offer a variety of pricing plans to suit your needs.
          </p>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          <div className="group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit">
            <h6 className="font-semibold mb-5 text-xl">Free</h6>

            <div className="flex mb-5">
              <span className="text-lg font-medium">$</span>
              <span className="price text-5xl h6 font-semibold mb-0">0</span>
              <span className="text-lg font-medium self-end mb-1">/mo</span>
            </div>

            <ul className="list-none text-slate-400">
              <li className="mb-1 flex">
                <i className="mdi mdi-check-circle-outline text-blue-500 text-[20px] align-middle me-2"></i>{" "}
                <span>
                  We offers a free month of service for new customers.
                </span>
              </li>
            </ul>
            <Link
              to="/contact-us"
              className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-500/5 hover:bg-blue-500 text-blue-500 hover:text-white w-full mt-5"
            >
              Free Demo
            </Link>
          </div>
          {pricingData.map((plan) => (
            <div
              key={plan.id}
              className={`group p-6 relative overflow-hidden shadow dark:shadow-gray-700 rounded-md h-fit ${
                plan.isPopular
                  ? "bg-gradient-to-tr from-blue-500 to-blue-700 text-white"
                  : "bg-white dark:bg-slate-900"
              }`}
            >
              {plan.isPopular && (
                <div className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white py-2 px-6 h6 text-lg font-medium">
                  Popular
                </div>
              )}
              <h6 className="font-semibold mb-5 text-xl">{plan.name}</h6>

              <div className="flex mb-5">
                <span className="text-lg font-medium">$</span>
                <span className="price text-5xl font-semibold mb-0">
                  {plan.price}
                </span>
                <span className="text-lg font-medium self-end mb-1">/mo</span>
              </div>

              <ul className="list-none text-slate-400">
                <li className="mb-1 flex">
                  <FaCheckCircle className="text-blue-500 text-[20px] align-middle me-2" />
                  <span>{plan.voters_limit}</span>
                </li>
              </ul>

              <Link
                to="/contact-us"
                className={`h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md ${
                  plan.isPopular
                    ? "bg-blue-500 text-white hover:bg-blue-600"
                    : "bg-blue-500/5 hover:bg-blue-500 text-blue-500 hover:text-white"
                } w-full mt-5`}
              >
                Get Started
              </Link>

              {plan.isPopular && (
                <p className="text-sm text-slate-400 mt-1.5">
                  <span className="text-red-600">*</span>T&C Apply
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
