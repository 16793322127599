import React, { useState } from "react";
import contact from "../assets/images/contact.svg";
import axios from "axios";
import { API } from "../config/urls";
import toast from "react-hot-toast";

export default function GetInTouch() {
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API.postContact, input)
      .then((res) => {
        toast.success("Form submitted successfully");
        setInput({ name: "", email: "", phone: "", subject: "", message: "" });
      })
      .catch((err) => toast.error("Form not submitted"));
  };

  return (
    <section
      className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            Get in touch
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            We will get back to you as soon as possible
          </p>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="lg:col-span-7 md:col-span-6">
            <img src={contact} alt="Contact" />
          </div>

          <div className="lg:col-span-5 md:col-span-6">
            <div className="lg:ms-5">
              <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                    <div className="lg:col-span-12">
                      <label htmlFor="name" className="font-semibold">
                        Name:
                      </label>
                      <input
                        name="name"
                        id="name"
                        value={input.name}
                        onChange={handleChange}
                        type="text"
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        placeholder="Name"
                      />
                    </div>

                    <div className="lg:col-span-6">
                      <label htmlFor="phone" className="font-semibold">
                        Phone:
                      </label>
                      <input
                        name="phone"
                        id="phone"
                        type="tel"
                        value={input.phone}
                        onChange={handleChange}
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="lg:col-span-6">
                      <label htmlFor="email" className="font-semibold">
                        Email:
                      </label>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        value={input.email}
                        onChange={handleChange}
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        placeholder="Email"
                      />
                    </div>
                    <div className="lg:col-span-12">
                      <label htmlFor="subject" className="font-semibold">
                        Subject:
                      </label>
                      <input
                        name="subject"
                        type="text"
                        value={input.subject}
                        onChange={handleChange}
                        id="subject"
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        placeholder="Subject"
                      />
                    </div>

                    <div className="lg:col-span-12">
                      <label htmlFor="message" className="font-semibold">
                        Comment:
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        value={input.message}
                        onChange={handleChange}
                        className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-500 text-white mt-2"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
