import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

export default function Client({ data }) {
  const settings = {
    container: ".tiny-three-item",
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 12,
    responsive: {
      992: {
        items: 3,
      },
      767: {
        items: 2,
      },
      320: {
        items: 1,
      },
    },
  };

  // Check if custom_fields is available and has items
  if (!data?.custom_fields || data.custom_fields.length === 0) {
    return null; // Or return a fallback UI, e.g., a loading spinner or a message
  }

  return (
    <section className="relative md:py-24 py-16" id="review">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <p className="text-slate-400 max-w-xl mx-auto">{data?.heading}</p>
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            {data?.sub_heading}
          </h3>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="tiny-three-item">
            <TinySlider settings={settings}>
              {data.custom_fields.map((item, index) => {
                return (
                  <div className="tiny-slide text-center" key={index}>
                    <div className="cursor-e-resize">
                      <div className="content relative rounded shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                        {/* <i className="mdi mdi-format-quote-open mdi-48px text-blue-500"></i> */}
                        <FaQuoteLeft
                          size={28}
                          className="text-blue-500 text-center"
                        />
                        {item?.custom_field_collections.map(
                          (collection, idx) => (
                            <p key={idx} className="text-slate-400">
                              {collection?.char}
                            </p>
                          )
                        )}
                      </div>

                      <div className="text-center mt-5">
                        <h6 className="mt-2 font-semibold">{item?.name}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </TinySlider>
          </div>
        </div>
      </div>
    </section>
  );
}
