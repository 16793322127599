import React from "react";
import { Link } from "react-router-dom";
import { FaVoteYea, FaUserCheck } from "react-icons/fa";
import {
  BsFillPeopleFill,
  BsCheck2Circle,
  BsCalendarCheck,
} from "react-icons/bs";
import { GiVote } from "react-icons/gi";

export default function Services({ data }) {
  const icons = [
    <FaVoteYea className="size-6 rotate-45" />,
    <GiVote className="size-6 rotate-45" />,
    <BsFillPeopleFill className="size-6 rotate-45" />,
    <FaUserCheck className="size-6 rotate-45" />,
    <BsCheck2Circle className="size-6 rotate-45" />,
    <BsCalendarCheck className="size-6 rotate-45" />,
  ];

  const bottomIcons = [
    <FaVoteYea className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <GiVote className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsFillPeopleFill className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <FaUserCheck className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsCheck2Circle className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
    <BsCalendarCheck className="size-48 text-red-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />,
  ];

  return (
    <section
      className="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
      id="services"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <p className="text-slate-400 max-w-xl mx-auto">{data?.heading}</p>
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            {data?.sub_heading}
          </h3>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
          {data?.custom_fields.map((item, index) => {
            return (
              <div
                className="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit"
                key={index}
              >
                <div className="flex items-center justify-center size-14 -rotate-45 bg-gradient-to-r from-transparent to-blue-500/10 text-blue-500 text-center rounded-full group-hover:bg-blue-500/10 duration-500">
                  {icons[index % icons.length]} {/* Icon */}
                </div>

                <div className="content mt-6 relative z-10">
                  <Link
                    to=""
                    className="title text-lg font-semibold hover:text-blue-500"
                  >
                    {item.name}
                  </Link>
                  {item?.custom_field_collections.map((collection, idx) => (
                    <p key={idx} className="text-slate-400 mt-3 text-justify">
                      {collection?.char}
                    </p>
                  ))}
                </div>

                <div className="absolute bottom-0 -end-16">
                  {bottomIcons[index % bottomIcons.length]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
