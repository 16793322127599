import { useEffect, useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import WOW from "wow.js";
import AOS from "aos";
import axios from "axios";
import { API } from "./config/urls";
import { Toaster } from "react-hot-toast";
import IndexThree from "./pages";
import DynamicPage from "./dynamic/dynamic";

function App() {
  // wow js scroll
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  // aos scroll
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true, once: true, disable: "mobile" });
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API.getdocs);
        setData(response.data?.content_urls);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<IndexThree />} />

        {data &&
          Object.keys(data).map((item, index) => (
            <Route
              key={index}
              path={item?.toLowerCase().replace(/\s/g, "-")}
              element={<DynamicPage />}
            />
          ))}
      </Routes>
    </>
  );
}

export default App;
