import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SEO from "../helpers/seo";
import { imgconvert } from "../helpers/imgconverter";
import { localURL } from "../config/urls";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import DynamicDetailPage from "../components/Details";

const DynamicPage = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  const [data, setData] = useState();

  useEffect(() => {
    const Getdata = () => {
      var config = {
        method: "get",
        url: localURL + "/api/v1/cms/content" + pathname + "/",
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          setData(response.data);
        })

        .catch(function (error) {
          console.log(error);
        });
    };
    Getdata();
  }, [pathname]);
  return (
    <>
      <SEO
        title={data && data.seo && data.seo.title}
        description={data && data.seo && data.seo.description}
        image={imgconvert(data && data.seo && data.seo.image)}
        name={data && data.seo && data.seo.title}
        type={data && data.seo && data.type}
      />
      <Navbar />
      <DynamicDetailPage data={data} />
      <Footer />
    </>
  );
};

export default DynamicPage;
